<template>
  <div>
    <subscribe v-if="showPlansPopup" :subscribePayload="subscribepayload" :closePopup="() => toggleSubscribePopup(false)" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Utility from "@/mixins/Utility.js";
export default {

  data() {
    return {
      subscribepayload: null,
      showPlansPopup: false,
      couponCode : null,
      planid : null
    };
  },

  computed: {
      ...mapGetters(["subscriberid"]),
  },

mounted() {
    this.actMetaData('subscription-plans');
    this.showPlansPopup = true;

    this.planid = this.$route.params.planId;
    this.couponCode = this.$route.params.coupon;

    let payload = {
      listPlans: "ALL",
      listType: "onlySubscribe"
    };

    this.subscribepayload = payload;
  },
  watch: {
    $route() {
      this.actMetaData('subscription-plans');
    }
  },

  methods: {

    ...mapMutations(["setPlanDeeplinkPath"]),

    toggleSubscribePopup(state) {
      let currentLanguage = this.getCurrentLanguageUrlBase();
      this.$router.push({ name: "Home", params: { lang: currentLanguage } });
    },
    // subscribeMetaData(){
    //   window.document.title = "Ultra Play Multiple Premium Subscription Plans with Best Offers";
    //   document.getElementsByTagName('meta')["keywords"].setAttribute('content', "Ultra Play subscription, Ultra Play subscription plans, Ultra Play premium subscription plans, Ultra Play subscription offer,");
    //   document.getElementsByTagName('meta')["description"].setAttribute('content', "Avail best offers on Ultra Play OTT multiple Premium Subscription Plans. Enjoy streaming authentic 2000+ hours of Marathi content. Get your Subscription now!");
    //   document.querySelector('meta[property="og\\:title"]').setAttribute('content', "Ultra Play Multiple Premium Subscription Plans with Best Offer");
    //   document.querySelector('meta[name="twitter\\:title"]').setAttribute('content', "Ultra Play Multiple Premium Subscription Plans with Best Offer");
    //   document.querySelector('meta[property="og\\:description"]').setAttribute('content', "Avail best offers on Ultra Play OTT multiple Premium Subscription Plans. Enjoy streaming authentic 2000+ hours of Marathi content. Get your Subscription now!");
    //   document.querySelector('meta[name="twitter\\:description"]').setAttribute('content', "Avail best offers on Ultra Play OTT multiple Premium Subscription Plans. Enjoy streaming authentic 2000+ hours of Marathi content. Get your Subscription now!");    
    //   let metaOgImage = 'https://firebasestorage.googleapis.com/v0/b/ultra-play.appspot.com/o/89_89.png?alt=media&token=689eb558-6fdd-4df1-a67e-4b903c71433';
    //   document.querySelector('meta[property="og\\:image"]').setAttribute('content', metaOgImage);
    //   document.querySelector('meta[property="og\\:image\\:width"]').setAttribute('content', 200);
    //   document.querySelector('meta[property="og\\:image\\:height"]').setAttribute('content', 200);
    // }
  },

  components: {
    subscribe: () => import(/* webpackChunkName: "subscribe" */ "@/components/Popups/Subscribe.vue"),
  },
   mixins: [Utility]
};
</script>

<style></style>
